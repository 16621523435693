import React from "react"
import BackgroundImage from 'gatsby-background-image'

const TitleBlock = ({ title, headerImage }) => (
		<BackgroundImage
		Tag="section"
		className="secondary-pages-title mb-3"
	  fluid={headerImage}
  ><h1>{title}</h1></BackgroundImage>
)

export default TitleBlock
