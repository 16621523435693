import React from "react"
import { Row, Col, Accordion, AccordionCollapse} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import TitleBlock from '../components/title-block'
import Sidebar from '../components/sidebar'

export default ({ data }) => {
	const headerImage = data.headerImage.childImageSharp.fluid

  return(
    <Layout pageInfo={{ pageName: "Frequently Asked Questions" }}>
		<SEO title="Questions About Waxing and Skincare"/>
  		<TitleBlock title="Frequently Asked Questions" headerImage={ headerImage } />
		<div>
		<Row className="px-3">
			<Col lg="8">
				<h2>Get the Scoop on Waxing and Skincare</h2>
				<Accordion className="faq-accordian mt-3">
					<div>
						<Accordion.Toggle as="div" className="accordian-question" eventKey="0">
							<p>How should I prepare for my waxing appointment?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="0">
							<div>
								<p>Before your waxing appointment, thoroughly clean and inspect the 
									desired treatment area. Any broken skin, cuts, scrapes, burns, etc. 
									need to heal before your appointment. The desired area of treatment 
									should have hair at least 1/4” long to ensure consistent removal. 
									Exfoliate and moisturize your skin a day or two before the appointment, 
									but don’t use lotion just before your appointment. </p>
								<p>It's also helpful to drink plenty of water to keep your skin hydrated leading 
									up to a waxing appointment; you might even consider using a moisturizer 
									during the days leading up to your session. With dry skin, your hair is more 
									likely to break off at the root during waxing.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
						<Accordion.Toggle as="div" className="accordian-question" eventKey="1">
							<p>What about post-waxing care?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="1">
							<div>
								<p>To avoid infection or breakouts, make sure to keep your skin clean after any waxing appointment. Avoid touching recently waxed skin as bacteria from your fingers can transfer to hair follicles. Wear loose-fitting clothing over any treated body part and postpone applying makeup for three to four hours. Avoid extended exposure to the sun, heavy workouts, and fragranced lotions for two days. After a Brazilian wax, we recommend avoiding sexual intercourse for 48 hours. </p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
						<Accordion.Toggle as="div" className="accordian-question" eventKey="2">
							<p>What is the difference between Hard Wax and Soft (Strip) Wax?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="2">
							<div>
								<p>Soft wax is applied warm and removed with a strip of cloth. Hard wax is applied to the skin and removed once it hardens. Hard wax is less painful and often preferred by those with sensitive skin. At Full Moon Waxing, we only use high-quality hard wax.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
						<Accordion.Toggle as="div" className="accordian-question" eventKey="3">
							<p>Will hair grow back thicker after waxing?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="3">
							<div>
								<p>Not at all. In fact, hair removed at the roots tends to grow back softer and finer over time.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
						<Accordion.Toggle as="div" className="accordian-question" eventKey="4">
							<p>Is waxing better than shaving?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="4">
							<div>
								<p>Yes. Waxing removes your hair at the root while shaving simply cuts the hair just above the skin. Waxed skin remains hairless longer, and, when your hair does grow back, it’s usually finer and softer. Waxing also exfoliates your skin, removing lifeless and dulling cells. This process speeds up your skin’s natural regeneration making your skin appear younger.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
						<Accordion.Toggle as="div" className="accordian-question" eventKey="5">
							<p>How does waxing compare to laser hair removal or electrolysis?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="5">
							<div>
								<p>Electrolysis or laser hair removal treatments don’t work for everyone and can be expensive. Waxing is the perfect alternative for those who aren’t satisfied with laser treatment or don’t want to spend the big bucks.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
						<Accordion.Toggle as="div" className="accordian-question" eventKey="6">
							<p>Does waxing hurt?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="6">
							<div>
								<p>Reactions will vary, but there is some pain with the waxing process. We’re experienced at making you feel comfortable and ensuring the process remains as pain-free as possible.  Full Moon Waxing uses all-natural elastic wax, and we apply it at your body’s normal temperature. This not only allows for more effective treatment; it’s less painful for you.</p>
								<p>The waxing process becomes more comfortable over time. Maintaining a regular schedule tends to make your hair less coarse and easier to remove.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
						<Accordion.Toggle as="div" className="accordian-question" eventKey="7">
							<p>Will waxing irritate my skin?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="7">
							<div>
								<p>Everyone is different, but there are potential reactions to be aware of. Most commonly, your skin may experience redness or irritation. Waxing could also leave your skin more vulnerable to infection. It’s important to clean your skin before your appointment and avoid waxing if you have any broken skin, cuts, scrapes, or burns. If you have any reason to believe your skin could be at risk, consult a physician or dermatologist before scheduling a waxing appointment.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
					<Accordion.Toggle as="div" className="accordian-question" eventKey="8">
							<p>How long before a big event (weddings, vacation, etc.), should I schedule a waxing appointment.</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="8">
							<div>
								<p>We recommend multiple waxing sessions leading up to a special occasion. Everyone’s skin is unique and responds differently to waxing. Allowing for more time will help you avoid dealing with any skin reactions during your big day. We suggest scheduling your first appointment at least six weeks before your big event then repeating your waxing procedure the week prior.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
					<Accordion.Toggle as="div" className="accordian-question" eventKey="9">
							<p>What do I wear during a waxing session?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="9">
							<div>
								<p>Wear loose-fitting clothes that provide access to the designated waxing areas.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
					<Accordion.Toggle as="div" className="accordian-question" eventKey="10">
							<p>Is it OK to tan before or after a waxing appointment?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="10">
							<div>
								<p>Avoid tanning two days before and after any waxing session. Recently tanned skin is more likely to peel or become irritated after having hair removed.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
					<Accordion.Toggle as="div" className="accordian-question" eventKey="11">
							<p>I’m self-conscious about my body. Should I still schedule a waxing appointment?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="11">
							<div>
								<p>Absolutely. Full Moon Waxing is a judgment-free zone. We’re not all models or bodybuilders, and that’s OK. Everyone deserves to have smooth, healthy-looking skin regardless of age, gender, or body composition.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
					<Accordion.Toggle as="div" className="accordian-question" eventKey="12">
							<p>I use a prescription skincare product. Can I still schedule a waxing appointment?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="12">
							<div>
								<p>Consult your dermatologist or physician before scheduling a waxing appointment if you’re taking any prescription skin medications. Such products could cause your skin to be removed with your hair during a waxing appointment. Please notify your esthetician if you are using any skincare prescriptions including, Retin A, Renova, Accutane, Adapalene, Avita, Tazorac Avage, Proactiv, Differin, and Strivectin.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
					<Accordion.Toggle as="div" className="accordian-question" eventKey="13">
							<p>How do I avoid ingrown hairs?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="13">
							<div>
								<p>For many, ingrown hairs are a fact of life.  Ingrown hairs are more common in people with coarse or curly hair as it’s more likely to bend back into your skin. </p>
								<p>Waxing regularly could potentially reduce your chance of getting ingrown hairs. If ingrown hairs are a problem for you, we recommend maintaining a regular waxing schedule and avoiding shaving your skin between sessions. Wash your skin daily while scrubbing in a circular motion, and use an exfoliating scrub if possible.</p>
								<p>As always, consult your physician or dermatologist if you have any concerns about infections or skin health issues. Everyone’s skin is different, and results will vary.</p>
							</div>
						</AccordionCollapse>
					</div>
					<div>
					<Accordion.Toggle as="div" className="accordian-question" eventKey="14">
							<p>How long should my hair be before I schedule a waxing appointment?</p>
						</Accordion.Toggle>
						<AccordionCollapse className="accordian-answer pl-1" eventKey="14">
							<div>
								<p>For best results, we recommend allowing your hair to grow at least 1/4” long. This gives our hard wax a better opportunity to grab onto the hair follicle and extract the root. Avoid shaving between waxing appointments as doing so could stimulate hair growth and counteract the benefits of your waxing regiment.</p>
							</div>
						</AccordionCollapse>
					</div>
				</Accordion>
			</Col>
			<Col lg="4">
				<Sidebar />
			</Col>
		</Row>
	  </div>
    </Layout>
	)
  }

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "default-title-image.jpg" }) {
		childImageSharp {
			fluid (quality: 90, maxWidth: 1140) {
				...GatsbyImageSharpFluid_withWebp
			}
		}
	}
  }
`
